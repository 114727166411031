import React, { useRef, useState, useMemo } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { useChain, useTrail, useTransition } from "react-spring";

import { usePaywall } from "../../../hooks/usePaywall";
import { useWindowSize } from "../../../hooks/customHooks";
import Paywall from "../../dashboard/paywall";
import SlideMenu from "./SlideMenu";
import { KicIcon, StyledIcon } from "./styles";
import { Text, Box, IconButton } from "../../../ui";
import { marketingUrl } from "../../../hooks/useMarketingRedirect";
import { useAuth } from "../../AuthWrapper/hooks";
import { useIsMobile } from "../../../hooks";
import { useRevenueCatSanityCheck } from "../../../features/subscriptions/hooks/useRevenueCatSanityCheck";

const ROUTES = [
  {
    label: "Recipes",
    route: "/dashboard/recipes",
    dataCy: "recipes",
  },
  {
    label: "Masterclasses",
    route: "/dashboard/masterclasses",
    dataCy: "masterclasses",
  },
  {
    label: "Community",
    route: "https://www.facebook.com/groups/2122591754723751/",
    skipsPaywall: true,
    dataCy: "community",
  },
  {
    label: "Gift Cards",
    route: "/gift-cards",
    skipsPaywall: true,
  },
];

export const AuthenticatedHeader = () => {
  const { logout, isAdmin } = useAuth();
  const router = useRouter();
  const { showPaywall } = usePaywall();
  const { isMobile } = useIsMobile();
  const routes = ROUTES;

  useRevenueCatSanityCheck();

  const [showMenu, setShowMenu] = useState(false);
  const [openPaywall, setOpenPaywall] = useState(false);

  const size = useWindowSize();
  const width = useMemo(() => size.width, [size]);

  const transRef = useRef();
  const transitions = useTransition(showMenu, null, {
    ref: transRef,
    from: { x: width || 0 },
    enter: { x: 0 },
    leave: { x: width || 0 },
  });

  const trailRef = useRef();
  const trail = useTrail(routes.length, {
    ref: trailRef,
    opacity: showMenu ? 1 : 0,
    x: showMenu ? 0 : 20,
    from: { opacity: 0, x: 20 },
    reverse: !showMenu,
    config: { mass: 1, tension: 180, friction: 15 },
  });

  const onClickLink = ({ route }) => {
    if (showPaywall) {
      setOpenPaywall(true);
    } else {
      router.push(route, route, { query: router.query });
    }
  };

  useChain(showMenu ? [transRef, trailRef] : [trailRef, transRef], [
    0,
    showMenu ? 0.2 : 0.6,
  ]);

  return isMobile ? (
    <>
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        py="sm"
        px="md"
      >
        <StyledIcon name="bars" onClick={() => setShowMenu(!showMenu)} />

        <Link href={marketingUrl}>
          <KicIcon name="kic-logo" />
        </Link>
        <IconButton
          onClick={() => {
            router.push("/dashboard/profile");
          }}
          variant="buttonSmallFilledLight"
          iconName="user-outline"
        />
      </Box>
      {transitions.map(
        ({ item, key, props: { x } }) =>
          item && (
            <SlideMenu
              key={key}
              style={{
                transform: x.interpolate((x) => `translateX(${x}px)`),
              }}
              toggleMenu={() => setShowMenu(!showMenu)}
              tabs={routes}
              logOut={logout}
              trail={trail}
            />
          ),
      )}
    </>
  ) : (
    <Box flexDirection="row" justifyContent="space-between" py="sm" px="md">
      <Link href={marketingUrl}>
        <StyledIcon name="kic-logo" big="true" />
      </Link>
      <Box flexDirection="row" alignItems="center">
        {isAdmin && (
          <Box mr="md" pr="sm">
            <Link href="/admin" prefetch={false} passHref>
              <Text variant="body" as="a" data-cy="auth-header-tab-admin">
                Admin
              </Text>
            </Link>
          </Box>
        )}
        {routes.map((route) => (
          <Box key={route.route} mr="md" pr="sm">
            {route.skipsPaywall ? (
              <Link
                key={route.route}
                href={route.route}
                as={route.as}
                target="_blank"
                passHref
              >
                <Text
                  variant="buttonMedium"
                  as="a"
                  color={
                    router.pathname === route.route
                      ? "contentContrastHigh"
                      : "contentContrastMid"
                  }
                >
                  {route.label}
                </Text>
              </Link>
            ) : (
              <Text
                key={route.route}
                variant="buttonMedium"
                css={{ cursor: "pointer" }}
                onClick={() => onClickLink(route)}
                data-cy={`auth-header-tab-${route.dataCy}`}
                color={
                  router.pathname === route.route
                    ? "contentContrastHigh"
                    : "contentContrastMid"
                }
              >
                {route.label}
              </Text>
            )}
          </Box>
        ))}
        <Text variant="body" css={{ cursor: "pointer" }} onClick={logout}>
          Log Out
        </Text>
      </Box>
      <IconButton
        data-cy="auth-header-tab-profile"
        onClick={() => {
          router.push("/dashboard/profile");
        }}
        variant="buttonSmallFilledLight"
        iconName="user-outline"
      />
      <Paywall
        isOpen={openPaywall}
        toggleModal={() => setOpenPaywall(!openPaywall)}
      />
    </Box>
  );
};
