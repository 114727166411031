import {
  PurchaseSubscriptionRequest,
  PurchaseSubscriptionResponse,
  PaymentIntentRequest,
  PaymentIntentResponse,
} from "./types";
import { SendRequest } from "../makeSendRequest";

export const createStripeApi = (sendRequest: SendRequest) => ({
  createGiftCardPaymentIntent: (payload: PaymentIntentRequest) => {
    return sendRequest<PaymentIntentResponse>({
      method: "post",
      path: "stripe/gift-card/create-payment-intent",
      version: "v1",
      data: payload,
      options: {
        requiresAuth: false,
      },
    });
  },
  purchaseSubscription: (payload: PurchaseSubscriptionRequest) => {
    return sendRequest<PurchaseSubscriptionResponse>({
      method: "post",
      path: "stripe/subscription/purchase",
      version: "v1",
      data: payload,
    });
  },

  createSetupIntent: () =>
    sendRequest<PaymentIntentResponse>({
      method: "post",
      path: "stripe/create-setup-intent",
      version: "v1",
    }),
});
