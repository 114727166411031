import { Challenge, ChallengeResponse } from "./types";

export const toChallenge = (
  challengeResponse: ChallengeResponse,
): Challenge => {
  return (
    challengeResponse && {
      ...challengeResponse,
      publishDate: new Date(challengeResponse.publishDate),
      unpublishDate: new Date(challengeResponse.unpublishDate),
      agnosticStartDate: new Date(challengeResponse.agnosticStartDate),
      agnosticEndDate:
        challengeResponse.agnosticEndDate &&
        new Date(challengeResponse.agnosticEndDate),
      programCollections:
        challengeResponse.programCollections?.map((collection) => ({
          ...collection,
          programs: collection.programs.map((program) => program._id),
        })) || [],
    }
  );
};
