import { CustomerInfo } from "@revenuecat/purchases-js";

export const KIC_ACCESS_ENTITLEMENT_IDENTIFIER = "Kic Subscription";

export const REVENUE_CAT_USER_ID_PREFIX = "RCUID_";
export type RevenueCatUserId = `${typeof REVENUE_CAT_USER_ID_PREFIX}${string}`;

export type SubscriptionInfo = {
  revenueCatUserId: RevenueCatUserId;
  customerInfo: CustomerInfo;
};
