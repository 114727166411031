import { useStoreState } from "easy-peasy";
import { useUser } from "../../../hooks";
import { logEvent } from "../../../utils/monitoring";
import { useSubscriptionInfo } from "./useSubscriptionInfo";
import { useDebounce } from "../../../hooks/useDebounce";
import { subscriptionInfoHasEntitlement } from "../utils/utils";

export const useRevenueCatSanityCheck = () => {
  {
    const subscriptionInfo = useSubscriptionInfo();
    const user = useUser();
    const isLoggedIn = !!user?._id;

    const revenueCatUserIdValid =
      user?.revenueCatUserId === subscriptionInfo?.revenueCatUserId;
    const hasEntitlement = subscriptionInfoHasEntitlement(subscriptionInfo);
    const isSubscribedRevenueCat = revenueCatUserIdValid && hasEntitlement;

    const isSubscribedStore = useStoreState(
      (s) => s.computators.hasValidSubscription,
    );

    useDebounce(
      () => {
        if (
          isLoggedIn &&
          !!subscriptionInfo &&
          subscriptionInfo?.revenueCatUserId === user?.revenueCatUserId
        ) {
          logEvent({
            eventName: "revenue_cat_sanity_check",
            isSubscribedRevenueCat,
            isSubscribedStore,
            isDiverged: isSubscribedRevenueCat != isSubscribedStore,
          });
        }
      },
      10000,
      [subscriptionInfo, user, isSubscribedRevenueCat, isSubscribedStore],
    );
  }
};
