import { SendRequest } from "../makeSendRequest";
import { AdminUserSubscription, RevenueCatPromotionalDuration } from "./types";

export const createAdminUserSubscriptionApi = (sendRequest: SendRequest) => ({
  getAdminUserSubscripion: (userId: string) =>
    sendRequest<AdminUserSubscription>({
      method: "get",
      path: "admin/get-user-subscription",
      version: "v3",
      params: { userId },
    }),
  grantPromotionalSubscripion: (
    userId: string,
    duration: RevenueCatPromotionalDuration,
  ) =>
    sendRequest<AdminUserSubscription>({
      method: "post",
      path: "admin/grant-promotional-subscription",
      version: "v3",
      data: { userId, duration },
    }),
  revokePromotionalSubscripions: (userId: string) =>
    sendRequest<AdminUserSubscription>({
      method: "post",
      path: "admin/revoke-promotional-subscription",
      version: "v3",
      data: { userId },
    }),
});
