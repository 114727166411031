import { useQuery } from "react-query";

import { TagName } from "../api/tags/types";

import { useCreateAPIClient } from "./useCreateAPIClient";

export const useTag = (name: TagName) => {
  const apiClient = useCreateAPIClient();

  const { data, error } = useQuery({
    queryKey: `tag:${name}`,
    queryFn: () => apiClient.tags.get(name),
    placeholderData: {},
    staleTime: Infinity,
  });

  if (error || !data.values) {
    return {};
  }

  return data.values;
};
